<template>
    <div>
        <el-main>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="活动分类：">
                    <el-select v-model="searchForm.cate_id" size="small" clearable>
                        <el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动标题：">
                    <el-input size="small" placeholder="请输入活动标题" v-model="searchForm.title"></el-input>
                </el-form-item>
                <el-form-item label="上架时间：">
                    <el-date-picker size="small" v-model="time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="支付订单：">
                    <el-input size="small" placeholder="请输入支付订单" v-model="searchForm.ordersn"></el-input>
                </el-form-item>
                <el-form-item label="活动时间：">
                    <el-date-picker size="small" v-model="act_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="活动状态：">
                    <el-select v-model="searchForm.astatus" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未开始" :value="1"></el-option>
                        <el-option label="活动中" :value="2"></el-option>
                        <el-option label="已结束" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上架状态：">
                    <el-select v-model="searchForm.is_up" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="上架" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据来源：">
                    <el-select v-model="searchForm.comfrom" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="平台上传" :value="1"></el-option>
                        <el-option label="商家申请" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动区域：">
                    <el-cascader v-model="searchForm.city_id" :options="allAreaList"
                        :props="{ value: 'id', label: 'name', children: '_child', emitPath: false }"
                        clearable></el-cascader>
                </el-form-item>
                <el-form-item label="商家名称：">
                    <el-input size="small" placeholder="请输入商家名称" v-model="searchForm.merchant_name"></el-input>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column width="90" align="center">
                    <template #header>
                        <div class="edit">
                            <span>排序</span>
                            <i class="el-icon-edit-outline"></i>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <el-input type="number" :min="0" size="small" v-model="scope.row.sort"
                            @change="setSort(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="cate_name" label="活动分类" width="110" align="center"></el-table-column>
                <el-table-column prop="title" label="活动标题" width="160" align="center"></el-table-column>
                <el-table-column prop="comfrom" label="数据来源" width="100" align="center">
                    <template v-slot="{ row }">{{ row.comfrom == 1 ? '平台上传' : '商家申请' }}</template>
                </el-table-column>
                <el-table-column prop="store_name" label="商家名称" width="120" align="center">
                    <template v-slot="{ row }">{{ row.store_name || '--' }}</template>
                </el-table-column>
                <el-table-column prop="merchant_mobile" label="商家电话" width="110" align="center">
                    <template v-slot="{ row }">{{ row.merchant_mobile || '--' }}</template>
                </el-table-column>
                <el-table-column prop="merchant_mobile" label="活动封面" width="110" align="center">
                    <template v-slot="{ row }">
                        <el-image :src="row.image" alt="" style="width: 60px;height: 60px;"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="integral" label="奖励积分/人" width="100" align="center"></el-table-column>
                <el-table-column prop="num" label="预估人数" width="100" align="center">
                    <template v-slot="{ row }">{{ row.num || '--' }}</template>
                </el-table-column>
                <el-table-column prop="yg_integral" label="额外奖励积分" width="110" align="center">
                    <template v-slot="{ row }">{{ row.yg_integral || '--' }}</template>
                </el-table-column>
                <el-table-column prop="price" label="支付金额（元）" width="120" align="center">
                    <template v-slot="{ row }">{{ row.price || '--' }}</template>
                </el-table-column>
                <el-table-column prop="ordersn" label="支付订单号" width="200" align="center"></el-table-column>
                <el-table-column prop="create_time" label="上架时间" width="160" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
                <el-table-column label="活动时间" width="160" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.start_time) }}-{{ getDateformat(row.end_time)
                    }}</template>
                </el-table-column>
                <el-table-column prop="merchant_mobile" label="活动区域" width="150" align="center">
                    <template v-slot="{ row }">{{ row.province_name ? row.province_name + row.city_name : '--' }}</template>
                </el-table-column>
                <el-table-column prop="merchant_mobile" label="活动状态" width="100" align="center">
                    <template v-slot="{ row }">{{ row.astatus == 1 ? '未开始 ' : row.astatus == 2 ? '活动中' : '已结束' }}</template>
                </el-table-column>
                <el-table-column label="展示/下架" align="center" min-width="100">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.is_up" :active-value="1" :inactive-value="0"
                            @change="changeIsUp(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="160" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleDetail(scope.row.id)" size="small">活动详情</el-button>
                        <el-button type="text"
                            @click="$router.push({ path: '/marketing/ActivityJoin', query: { activity_id: scope.row.id } })"
                            size="small">参与列表</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="活动详情" :visible.sync="dialogVisible" width="800px">
                <el-form label-width="140px">
                    <el-form-item label="商家名称：">
                        <div>{{ info.store_name || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="商家电话：">
                        <div>{{ info.merchant_mobile || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动分类：">
                        <div>{{ info.cate_name || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动标题：">
                        <div>{{ info.title || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动区域：">
                        <div>{{ info.province_name ? info.province_name + info.city_name : '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动地址：">
                        <div>{{ info.address || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动时间：">
                        <div>{{ getDateformat(info.start_time) }}至{{ getDateformat(info.end_time) }}</div>
                    </el-form-item>
                    <el-form-item label="奖励积分/人：">
                        <div>{{ info.integral || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="预估人数：">
                        <div>{{ info.num || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="额外奖励积分：">
                        <div>{{ info.yg_integral || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="支付金额（元）：">
                        <div>{{ info.price || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动图片：">
                        <el-image :src="info.image" style="width:100px;height: 100px;"></el-image>
                    </el-form-item>
                    <el-form-item label="活动详情：">
                        <div v-html="info.detail" class="detail"></div>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                cate_id: '',
                title: '',
                start_time: '',
                end_time: '',
                act_start_time: '',
                act_end_time: '',
                astatus: '',
                comfrom: '',
                city_id: '',
                is_up: '',
                name: '',
                ordersn: '',
            },
            time: '',
            act_time: '',
            allAreaList: [],
            classify: [],
            dialogVisible: false,
            info: ''
        };
    },
    created () {
        this.getList();
        this.getClassify()
        this.getArea()
    },
    methods: {
        // 获取省市区信息
        getArea () {
            this.$axios
                .get(this.$api.set.areaList, {
                    tree: 1,
                    level: 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.allAreaList = res.result.list;
                    }
                });
        },
        getClassify () {
            this.$axios.post(this.$api.marketing.ActivityCateList, {
                page: 1,
                rows: 100
            }).then(res => {
                if (res.code == 0) {
                    this.classify = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        setSort (row) {
            this.$axios.post(this.$api.repair.marketing.ActivityEdit, {
                title: row.title,
                province_id: row.province_id,
                city_id: row.city_id,
                address: row.address,
                start_time: row.start_time,
                end_time: row.end_time,
                image: row.image,
                detail: row.detail,
                id: row.id,
                cate_id: row.cate_id,
                sort: row.sort,
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('编辑成功');
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                cate_id: '',
                title: '',
                start_time: '',
                end_time: '',
                act_start_time: '',
                act_end_time: '',
                astatus: '',
                comfrom: '',
                city_id: '',
                is_up: '',
                name: '',
                ordersn: '',
            };
            this.time = ''
            this.act_time = ''
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            if (this.time) {
                searchForm.start_time = this.time[0] / 1000
                searchForm.end_time = this.time[1] / 1000
            }
            if (this.act_time) {
                searchForm.act_start_time = this.act_time[0] / 1000
                searchForm.act_end_time = this.act_time[1] / 1000
            }
            this.$axios.post(this.$api.marketing.ActivityList, searchForm).then(res => {
                if (res.code == 0) {
                    let { list } = res.result
                    list.forEach(item => {
                        item.sort = item.sort || 0
                    })
                    this.list = list;
                    this.total_number = res.result.total;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        changeIsUp (row) {
            this.$axios.post(this.$api.repair.marketing.ActivityUp, {
                id: row.id,
                is_up: row.is_up
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('编辑成功');
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleDetail (id) {
            this.$axios.post(this.$api.marketing.ActivityInfo, { id }).then(res => {
                if (res.code == 0) {
                    this.info = res.result
                    this.dialogVisible = true
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}

.detail {
    width: 600px;

    /deep/img {
        width: 100%;
        height: auto;
    }
}
</style>
  